import {
  Card,
  Row,
  Col,
  Alert,
  Popconfirm,
  Button,
  Form,
  Drawer,
  Switch,
  Radio,
  message,
  Tree,
  Collapse,
} from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import axios from "axios";
import { DownOutlined } from "@ant-design/icons";
import { LIST_FORMS } from "../../literals";
import { jsPDF } from "jspdf";
import { useEffect, useState, useRef, useReducer } from "react";
import TANFDT from "./Tanf_DT";
import Registration from "./Registration";
import TANFWPCOOP from "./TANF_WPCOOP";
import CHILDCAREONLY from "./ChildCare";
import CC_TANF from "./CC_TANF";
import ScreenName_VR from "./ScreenName_VR";
import ScreenName_PW from "./ScreenName_PW";
import moment from "moment";
import CS_AllPrograms from "./CS_AllPrograms";
import CS_AuthRep from "./CS_AuthRep";
import CS_SummaryScreen from "./CS_SummaryScreen";
import INDEMOSCREEN from "./IDS";
import CITIDENTITYLIST from "./CIL";
import HOUSEHOLDSTATUSLIST from "./HS_StatusList";
import RELATIONSHIPLIST from "./RelationshipList";
import PREGNANCYLIST from "./PregnancyList";
import OTHERPROGRAMLIST from "./OtherProgramList";
import NONCOMPLIANCELIST from "./NC_List";
import TIMELIMITSUMMARY from "./TimeLimitSummary";
import PURCHASEPREPARELIST from "./PP_List";
import SCHOOLATTLIST from "./SA_List";
import LIVINGARRANGEMENT from "./LivingArrangement";
import NONCUSTPARENTLIST from "./NC_ParentList";
import MEDCONDLIST from "./MC_List";
import EMPLISTINCLIST from "./EL_IncomeList";
import RESOURCELIST from "./ResourceList";
import EXPENSELIST from "./ExpenseList";
import DCEXPENSELIST from "./DC_ExpenseList";
import MDEXPENSELIST from "./MD_ExpenseList";
import MDCAREEXPENSELIST from "./MDC_ExpenseList";
import CHILDCARENEEDLIST from "./CC_NeedList";
import PCSCHEDULEDLIST from "./PC_ScheduleList";
import FAMPLANCHILDCARE from "./FP_ChildCareList";
import TANFSUSPENSIONBASED from "./SB_TANF";
import DVSASS from "./DVSA_StalkingScreening";
import ICEBT from "./IC_EBT";
import EXPEDITEDSTATUS from "./IC_ExpeditedStatus";
import ICMAWRS from "./IC_MAWRS";
import VERIFICATIONSLIST from "./VerificationsList";
import TODO from "./TODO";
import RUNEDBC from "./RunEDBC";
import NARRATIVE from "./Narrative";
import AnswerCall from "./AnswerCall";
import RightsAndResponsibilities from "./RightsAndResp";
import ReportingPage from "./ReportingPage";
import useFormFetch from "../../hooks/useFormFetch";
import { LAMBDAS } from "../../literals";
import { InfoCircleTwoTone } from "@ant-design/icons";
import RETRIEVECONTACT from "./RetrieveContact";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import useDataFetch from "../../hooks/useDataFetch";
import useSaveData from "../../hooks/useSaveEffect";
const { Panel } = Collapse;

let datasource = [];
let datasourceList = [];

const CustomerForm = (props) => {
  //
  const forceUpdate = useReducer(x => x + 1, 0)[1]
  const [textValue, setTextValue] = useState("");
  const [contactID, setContactID] = useState("");
  const [agentID, setAgentID] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [documentName, setDocumentName] = useState("");
  // const [datasourceList, setDatasourceList] = useState([]);
  const [callAnswered, setCallAnswered] = useState("");
  const [assessmentForm, setAssessmentForm] = useState(false);
  const [registration_form] = Form.useForm();
  const [answer_call_form] = Form.useForm();
  const [right_resp_form] = Form.useForm();
  const [tanfdt_form] = Form.useForm();
  const [tanfwpcoop_form] = Form.useForm();
  const [childcareonly_form] = Form.useForm();
  const [cctanf_form] = Form.useForm();
  const [screennamevr_form] = Form.useForm();
  const [screennamepw_form] = Form.useForm();
  const [csallprograms_form] = Form.useForm();
  const [csauthrep_form] = Form.useForm();
  const [cssummaryscreen_form] = Form.useForm();
  const [reportingpage_form] = Form.useForm();
  const [indemoscreen_form] = Form.useForm();
  const [citidentitylist_form] = Form.useForm();
  const [householdstatuslist_form] = Form.useForm();
  const [relationshiplist_form] = Form.useForm();
  const [pregnancylist_form] = Form.useForm();
  const [otherprogramlist_form] = Form.useForm();
  const [noncompliancelist_form] = Form.useForm();
  const [timelimitsummary_form] = Form.useForm();
  const [purchasepreparelist_form] = Form.useForm();
  const [schoolattlist_form] = Form.useForm();
  const [livingarrangement_form] = Form.useForm();
  const [noncustparentlist_form] = Form.useForm();
  const [medcondlist_form] = Form.useForm();
  const [emplistinclist_form] = Form.useForm();
  const [resourcelist_form] = Form.useForm();
  const [expenselist_form] = Form.useForm();
  const [dcexpenselist_form] = Form.useForm();
  const [mdexpenselist_form] = Form.useForm();
  const [mdcareexpenselist_form] = Form.useForm();
  const [childcareneedlist_form] = Form.useForm();
  const [pcscheduledlist_form] = Form.useForm();
  const [famplanchildcare_form] = Form.useForm();
  const [tanfsuspensionbased_form] = Form.useForm();
  const [dvsass_form] = Form.useForm();
  const [icebt_form] = Form.useForm();
  const [expeditedstatus_form] = Form.useForm();
  const [icmawrs_form] = Form.useForm();
  const [verificationslist_form] = Form.useForm();
  const [todo_form] = Form.useForm();
  const [runedbc_form] = Form.useForm();
  const [narrative_form] = Form.useForm();
  const [retrievecontact_form] = Form.useForm();
  const [payLoad, setpayLoad] = useState(null);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [completed, setCompleted] = useState("");

  useEffect(()=>{
    answer_call_form.setFieldsValue({
      'Agent Name': props.agentName,
    });
    
    right_resp_form.setFieldsValue({
      'Worker Name': props.agentName,
    });

    narrative_form.setFieldsValue({
      'Worker Name:': props.agentName
    })

  }, [props.agentName])

  useEffect(()=>{
    registration_form.setFieldsValue({
      'KEES Case Number': props.caseNumber,
    });
  }, [props.caseNumber])

  useEffect(()=>{
    answer_call_form.setFieldsValue({
      'Callback Number': props.customerNumber,
    });
  }, [props.customerNumber])




  window.addEventListener(
    "agentEvent",
    async function (e) {
      var agentID = e.detail.id;
      setAgentID(agentID);
    },
    false
  );

  const getContact = async () => {
    try {
      const { data: response } = await axios.get(
        LAMBDAS.GET_FORM_DETAILS +
          contactName +
          "&phoneNumber=" +
          contactPhoneNumber
      );
      let get_data = response.response;
      datasourceList = get_data.form_details;
      setCallAnswered(get_data.callAnswered);
      if (response.response !== "DATA DOES NOT EXIST") {
        populate_form();
        retrievecontact_form.resetFields();
        onClose();
      } else {
        setMsg(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const sendContactDetails = (contact_name, contact_number, items) => {
    const c_date = new Date();
    let time_date_submitted = moment(c_date, "MMMM Do YYYY, h:mm:ss a").format(
      "LLL"
    );
    let contactDetails = {
      contactId: "12hg2ftyyt1cchut1qB7uigCJSASxnh127hfbhjcvrx1c",
      contactName: contact_name,
      contactPhoneNumber: contact_number,
      callAnswered: callAnswered,
      dateTimeSubmitted: time_date_submitted,
      formDetails: items,
    };

    fetch(LAMBDAS.SAVE_CONTACT, {
      method: "POST",
      body: JSON.stringify(contactDetails),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Success:", result.response);
        if (result.response) {
          clearFields();
        } else {
          message.error("Failed to save. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const clearFields = () => {
    setContactID("");
    setAgentID("");
    setCallAnswered("");
    setContactName("");
    setContactName("");
    setCompleted("");
    answer_call_form.resetFields();
    right_resp_form.resetFields();
    registration_form.resetFields();
    tanfdt_form.resetFields();
    tanfwpcoop_form.resetFields();
    childcareonly_form.resetFields();
    cctanf_form.resetFields();
    screennamevr_form.resetFields();
    screennamepw_form.resetFields();
    csallprograms_form.resetFields();
    csauthrep_form.resetFields();
    cssummaryscreen_form.resetFields();
    reportingpage_form.resetFields();
    indemoscreen_form.resetFields();
    citidentitylist_form.resetFields();
    householdstatuslist_form.resetFields();
    relationshiplist_form.resetFields();
    pregnancylist_form.resetFields();
    otherprogramlist_form.resetFields();
    noncompliancelist_form.resetFields();
    timelimitsummary_form.resetFields();
    purchasepreparelist_form.resetFields();
    schoolattlist_form.resetFields();
    livingarrangement_form.resetFields();
    noncustparentlist_form.resetFields();
    medcondlist_form.resetFields();
    emplistinclist_form.resetFields();
    resourcelist_form.resetFields();
    expenselist_form.resetFields();
    dcexpenselist_form.resetFields();
    mdexpenselist_form.resetFields();
    mdcareexpenselist_form.resetFields();
    childcareneedlist_form.resetFields();
    pcscheduledlist_form.resetFields();
    famplanchildcare_form.resetFields();
    tanfsuspensionbased_form.resetFields();
    dvsass_form.resetFields();
    icebt_form.resetFields();
    expeditedstatus_form.resetFields();
    icmawrs_form.resetFields();
    verificationslist_form.resetFields();
    todo_form.resetFields();
    runedbc_form.resetFields();
    narrative_form.resetFields();
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setMsg(false);
  };
  let url_pdf = LAMBDAS.FORM_PDF + documentName;
  const { data, error } = useFormFetch(
    url_pdf,
    payLoad,
    "PUT",
    "application/pdf"
  );

  // let url_getData =
  //   LAMBDAS.GET_FORM_DETAILS +
  //   contactName +
  //   "&phoneNumber=" +
  //   contactPhoneNumber;
  // const { data1, error1 } = useDataFetch(
  //   url_getData,
  //   payLoad,
  //   "GET",
  //   "application/json"
  // );
  // let url_saveData = LAMBDAS.SAVE_CONTACT;
  // const { data2, error2 } = useSaveData(
  //   url_saveData,
  //   payLoad,
  //   "POST",
  //   "application/json"
  // );

  const populate_form = () => {
    answer_call_form.setFieldsValue(
      datasourceList[0]["TANF/CC/FOOD ASSISTANCE PROGRAM"]
    );
    right_resp_form.setFieldsValue(
      datasourceList[1]["RIGHTS AND RESPONSIBILITIES"]
    );
    registration_form.setFieldsValue(datasourceList[2]["REGISTRATION"]);

    let tanfdt_data =
      datasourceList[3]["TANF Drug Testing Program Requirements"];
    if (
      tanfdt_data.hasOwnProperty("Date of Conviction") &&
      tanfdt_data["Date of Conviction"] !== "" &&
      tanfdt_data["Date of Conviction"] !== null
    ) {
      tanfdt_data["Date of Conviction"] = moment(
        tanfdt_data["Date of Conviction"]
      );
    }

    tanfdt_form.setFieldsValue(tanfdt_data);
    tanfwpcoop_form.setFieldsValue(
      datasourceList[4]["TANF ONLY and WORK PROGRAM COOP"]
    );
    childcareonly_form.setFieldsValue(datasourceList[5]["CHILD CARE ONLY"]);
    cctanf_form.setFieldsValue(datasourceList[6]["CC/TANF ONLY"]);
    screennamevr_form.setFieldsValue(datasourceList[7]["VOTER REGISTRATION"]);
    screennamepw_form.setFieldsValue(datasourceList[8]["PROGRAM WITHDRAW"]);
    csallprograms_form.setFieldsValue(
      datasourceList[9]["CASE SUMMARY (All Programs)"]
    );
    csauthrep_form.setFieldsValue(datasourceList[10]["AUTHORIZED REP"]);
    cssummaryscreen_form.setFieldsValue(
      datasourceList[11]["CONTACT SUMMARY SCREEN (All Programs)"]
    );
    reportingpage_form.setFieldsValue(
      datasourceList[12]["REPORTING PAGE (Reviews/IR ONLY)"]
    );
    indemoscreen_form.setFieldsValue(
      datasourceList[13]["INDIVIDUAL DEMOGRAPHICS SCREEN (All Programs)"]
    );
    citidentitylist_form.setFieldsValue(
      datasourceList[14]["CITIZENSHIP/ IDENTITY LIST (All Programs)"]
    );
    householdstatuslist_form.setFieldsValue(
      datasourceList[15]["HOUSEHOLD STATUS LIST"]
    );
    relationshiplist_form.setFieldsValue(
      datasourceList[16]["RELATIONSHIP LIST (All Programs)"]
    );
    let pregnancylist_data =
      datasourceList[17]["PREGNANCY LIST (All Programs)"];
    if (
      pregnancylist_data.hasOwnProperty("Due Date") &&
      pregnancylist_data["Due Date"] !== "" &&
      pregnancylist_data["Due Date"] !== null
    ) {
      pregnancylist_data["Due Date"] = moment(pregnancylist_data["Due Date"]);
    }

    pregnancylist_form.setFieldsValue(pregnancylist_data);
    otherprogramlist_form.setFieldsValue(
      datasourceList[18]["OTHER PROGRAM LIST (All Programs)"]
    );
    noncompliancelist_form.setFieldsValue(
      datasourceList[19]["NON-COMPLIANCE LIST (All Programs)"]
    );
    timelimitsummary_form.setFieldsValue(
      datasourceList[20]["TIME LIMIT SUMMARY (All Programs)"]
    );
    purchasepreparelist_form.setFieldsValue(
      datasourceList[21]["PURCHASE AND PREPARE LIST (FA)"]
    );
    schoolattlist_form.setFieldsValue(
      datasourceList[22]["SCHOOL ATTENDANCE LIST (All Programs)"]
    );
    livingarrangement_form.setFieldsValue(
      datasourceList[23]["LIVING ARRANGEMENTS"]
    );
    noncustparentlist_form.setFieldsValue(
      datasourceList[24]["NONCUSTODIAL PARENT LIST (All Programs)"]
    );
    medcondlist_form.setFieldsValue(
      datasourceList[25]["MEDICAL CONDITION LIST (FA, TANF)"]
    );
    emplistinclist_form.setFieldsValue(
      datasourceList[26]["EMPLOYMENT LIST /INCOME LIST"]
    );
    resourcelist_form.setFieldsValue(
      datasourceList[27]["RESOURCE LIST (All Programs)"]
    );
    expenselist_form.setFieldsValue(
      datasourceList[28]["SHELTER - EXPENSE LIST (FA, TANF)"]
    );
    dcexpenselist_form.setFieldsValue(
      datasourceList[29]["DEPENDENT CARE/ CSS - EXPENSE LIST (FA, TANF)"]
    );
    mdexpenselist_form.setFieldsValue(
      datasourceList[30]["MEDICAL - EXPENSE LIST (FA, TANF)"]
    );
    mdcareexpenselist_form.setFieldsValue(
      datasourceList[31]["MEDICARE EXPENSE LIST (FA Only)"]
    );
    childcareneedlist_form.setFieldsValue(
      datasourceList[32][
        "CHILD CARE NEED LIST – PARENT AND/ OR CHILD CARE NEED LIST – CHILD (CC Only)"
      ]
    );
    pcscheduledlist_form.setFieldsValue(
      datasourceList[33][
        "PARENT SCHEDULE LIST AND/ OR CHILD SCHEDULE LIST (CC Only)"
      ]
    );
    famplanchildcare_form.setFieldsValue(
      datasourceList[34]["FAMILY PLAN – CHILD CARE LIST (CC Only)"]
    );
    tanfsuspensionbased_form.setFieldsValue(
      datasourceList[35][
        "TANF Suspicion Based Drug Testing (Only those who meet suspicion-based indicators)"
      ]
    );
    dvsass_form.setFieldsValue(
      datasourceList[36][
        "DOMESTIC VIOLENCE, SEXUAL ASSAULT, OR STALKING SCREENING (REQUIRED)"
      ]
    );
    icebt_form.setFieldsValue(datasourceList[37]["EBT"]);
    expeditedstatus_form.setFieldsValue(datasourceList[38]["EXPEDITED STATUS"]);
    icmawrs_form.setFieldsValue(
      datasourceList[39]["MANDATORY ABAWD / E&T / WORK REGISTRANT SCRIPTS"]
    );
    let verificationslist_data = datasourceList[40]["VERIFICATIONS LIST"];
    if (
      verificationslist_data.hasOwnProperty("Due Date") &&
      verificationslist_data["Due Date"] !== "" &&
      verificationslist_data["Due Date"] !== null
    ) {
      verificationslist_data["Due Date"] = moment(
        verificationslist_data["Due Date"]
      );
    }

    verificationslist_form.setFieldsValue(verificationslist_data);
    todo_form.setFieldsValue(datasourceList[41]["TODO"]);
    runedbc_form.setFieldsValue(datasourceList[42]["RUN EDBC"]);
    narrative_form.setFieldsValue(datasourceList[43]["NARRATIVE"]);
  };

  useEffect(() => {
    setContactID(props.contactID);
    const c_date = new Date();
    setCallAnswered(moment(c_date, "MMMM Do YYYY, h:mm:ss a").format("LLL"));
  }, [props.contactID]);

  const addZero = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };

  const sendData = () => {
    switch ((data === null, error === null)) {
      case (false, true):
        // sessionStorage.clear();
        clearFields();

        break;
      case (true, false):
        console.log("INSIDE TRUE FALSE");

        break;
      default:
        break;
    }
  };

  const onFinish_retrievecontact = (values) => {};
  const onFinish_answerCall = (values) => {};
  const onFinish_registration = (values) => {};
  const onFinish_rightAndResp = (values) => {};
  const onFinish_tanfdt = (values) => {};
  const onFinish_tanfwpcoop = (values) => {};
  const onFinish_childcareonly = (values) => {};
  const onFinish_cctanf = (values) => {};
  const onFinish_screennamevr = (values) => {};
  const onFinish_screennamepw = (values) => {};
  const onFinish_csallprograms = (values) => {};
  const onFinish_csauthrep = (values) => {};
  const onFinish_cssummaryscreen = (values) => {};
  const onFinish_indemoscreen = (values) => {};
  const onFinish_citidentitylist = (values) => {};
  const onFinish_householdstatuslist = (values) => {};
  const onFinish_relationshiplist = (values) => {};
  const onFinish_pregnancylist = (values) => {};
  const onFinish_otherprogramlist = (values) => {};
  const onFinish_noncompliancelist = (values) => {};
  const onFinish_timelimitsummary = (values) => {};
  const onFinish_purchasepreparelist = (values) => {};
  const onFinish_schoolattlist = (values) => {};
  const onFinish_livingarrangement = (values) => {};
  const onFinish_noncustparentlist = (values) => {};
  const onFinish_medcondlist = (values) => {};
  const onFinish_emplistinclist = (values) => {};
  const onFinish_resourcelist = (values) => {};
  const onFinish_expenselist = (values) => {};
  const onFinish_dcexpenselist = (values) => {};
  const onFinish_mdexpenselist = (values) => {};
  const onFinish_mdcareexpenselist = (values) => {};
  const onFinish_childcareneedlist = (values) => {};
  const onFinish_pcscheduledlist = (values) => {};
  const onFinish_famplanchildcare = (values) => {};
  const onFinish_tanfsuspensionbased = (values) => {};
  const onFinish_dvsass = (values) => {};
  const onFinish_icebt = (values) => {};
  const onFinish_expeditedstatus = (values) => {};
  const onFinish_icmawrs = (values) => {};
  const onFinish_verificationslist = (values) => {};
  const onFinish_todo = (values) => {};
  const onFinish_runedbc = (values) => {};
  const onFinish_narrative = (values) => {};
  const onFinish_reportingpage = (values) => {};

  const getContactDetails = () => {
    retrievecontact_form.submit();
    getContact();
  };

  const submit_forms = () => {
    registration_form.submit();
    answer_call_form.submit();
    right_resp_form.submit();
    tanfdt_form.submit();
    tanfwpcoop_form.submit();
    childcareonly_form.submit();
    cctanf_form.submit();
    screennamevr_form.submit();
    screennamepw_form.submit();
    csallprograms_form.submit();
    csauthrep_form.submit();
    cssummaryscreen_form.submit();
    reportingpage_form.submit();
    indemoscreen_form.submit();
    citidentitylist_form.submit();
    householdstatuslist_form.submit();
    relationshiplist_form.submit();
    pregnancylist_form.submit();
    otherprogramlist_form.submit();
    noncompliancelist_form.submit();
    timelimitsummary_form.submit();
    purchasepreparelist_form.submit();
    schoolattlist_form.submit();
    livingarrangement_form.submit();
    noncustparentlist_form.submit();
    medcondlist_form.submit();
    emplistinclist_form.submit();
    resourcelist_form.submit();
    expenselist_form.submit();
    dcexpenselist_form.submit();
    mdexpenselist_form.submit();
    mdcareexpenselist_form.submit();
    childcareneedlist_form.submit();
    pcscheduledlist_form.submit();
    famplanchildcare_form.submit();
    tanfsuspensionbased_form.submit();
    dvsass_form.submit();
    icebt_form.submit();
    expeditedstatus_form.submit();
    icmawrs_form.submit();
    verificationslist_form.submit();
    todo_form.submit();
    runedbc_form.submit();
    narrative_form.submit();
    confirm();
    // if (!assessmentForm) {
    //   confirm();
    // }
  };

  const cancel = (e) => {
    console.log(e);
  };
  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };
  const sendFormData = (form_data) => {
    //replace with any final validation checks if needed
    if (true) {
      setpayLoad(form_data);
    }
  };
  const confirm = () => {
    let display_data = [];
    let temp_data = "";
    let clientName = "";
    let callbackNumber = "";
    let prep_datasource = [];
    display_data.push("ID: " + contactID);
    display_data.push("Date and Time Call Accepted: " + callAnswered);
    for (const item of LIST_FORMS) {
      for (let key0 in datasource) {
        for (let key1 in datasource[key0]) {
          for (let temp_key in datasource[key0][key1]) {
            if (datasource[key0][key1][temp_key] === undefined)
              datasource[key0][key1][temp_key] = "";
          }
          if (item === key1) {
            prep_datasource.push(datasource[key0]);
          }
        }
      }
    }

    for (let i = 0; i <= prep_datasource.length; i++) {
      for (let key in prep_datasource[i]) {
        display_data.push("<======" + key + "======>");
        for (const [key2, value] of Object.entries(prep_datasource[i][key])) {
          if (key2 === "Client Name") {
            clientName = value;
          }
          if (key2 === "Callback Number") {
            callbackNumber = value;
          }
          temp_data = key2 + ":" + value;
          display_data.push(temp_data.replace(/(\r\n|\n|\r)/gm, ""));
        }
      }
    }
    sendContactDetails(clientName, callbackNumber, prep_datasource);

    const PDF_WIDTH = 816;
    const PDF_HEIGHT = PDF_WIDTH * 1.2941;
    const doc = new jsPDF("p", "pt"[(PDF_WIDTH, PDF_HEIGHT)]);
    doc.setFontSize(8);
    const wrappedText = doc.splitTextToSize(display_data, 180);
    let iterations = 1;
    const margin = 15;
    const defaultYJump = 5;
    const pageHeight = doc.internal.pageSize.height;

    wrappedText.forEach((line) => {
      let posY = margin + defaultYJump * iterations++;
      if (posY > pageHeight - margin) {
        doc.addPage();
        iterations = 1;
        posY = margin + defaultYJump * iterations++;
      }
      doc.text(15, posY, line);
    });

    const cDate = new Date();
    let currentDate = cDate.toDateString().split(" ").splice(1, 3).join("_");
    let currentTime = new Date();
    let docName =
      clientName +
      "_" +
      currentDate +
      "_" +
      addZero(currentTime.getHours()) +
      addZero(currentTime.getMinutes()) +
      addZero(currentTime.getSeconds()) +
      ".pdf";
    setDocumentName(docName);
    let blob = doc.output("blob");
    let formData = new FormData();
    formData.append("file", blob, docName);
    if (completed === "COMPLETED") {
      doc.save(docName);
      sendFormData(formData);
      sendData();
    }
    // answer_call_form.scrollToField("agent_name");
  };

  const radioChange = (e) => {
    console.log(`radio to ${e.target.value}`);
    setCompleted(e.target.value);
  };

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div style={{ maxHeight: "430px", overflow: "auto" }}>
      <Drawer title="Retrieve Interview Details" onClose={onClose} open={open}>
        {msg ? (
          <Alert
            message="Data does not exist. Please try again."
            banner
            closable
            showIcon
            type="error"
            style={{ marginBottom: "15px" }}
          />
        ) : (
          ""
        )}
        <RETRIEVECONTACT
          onFinish={onFinish_retrievecontact}
          form={retrievecontact_form}
        />

        <Row gutter={4}>
          <Col span={12}>
            <Popconfirm
              title="Click Yes to submit."
              description="Confirm details before clicking submit."
              onConfirm={getContactDetails}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => {
                  retrievecontact_form
                    .validateFields()
                    .then((e) => {
                      // console.log("[retrievecontact_form][VALIDATION]", e);
                      setContactName(e["Name"]);
                      setContactPhoneNumber(e["Phone Number"]);
                    })
                    .catch((errors) => {
                      // console.log(
                      //   "[retrievecontact_form][ERROR-VALIDATION]",
                      //   errors
                      // );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[retrievecontact_form][ERROR-VALIDATION]",
                          errors
                        );
                      }
                    });
                }}
              >
                SUBMIT
              </Button>
            </Popconfirm>
          </Col>
          <Col span={12}>
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                retrievecontact_form.resetFields();
              }}
            >
              RESET
            </Button>
          </Col>
        </Row>
      </Drawer>

      <Collapse defaultActiveKey={["1"]} onChange={onChange}>
        <Panel
          header="TANF/CC/FOOD ASSISTANCE PROGRAM"
          key="1"
          extra={
            <div style={{ textAlign: "right" }}>
              <Button onClick={showDrawer}>
                <EllipsisOutlined />
              </Button>
            </div>
          }
        >
          <AnswerCall
            callAnswered={callAnswered}
            agentName={props.agentName}
            callbackNumber={props.customerNumber}
            onFinish={onFinish_answerCall}
            form={answer_call_form}
          />
        </Panel>
        <Panel header="RIGHTS AND RESPONSIBILITIES" key="2">
          <RightsAndResponsibilities
            agentName={props.agentName}
            onFinish={onFinish_rightAndResp}
            form={right_resp_form}
          />
        </Panel>
        <Panel header="REGISTRATION" key="3">
          <Registration
            queue={props.queue}
            caseNumber={props.caseNumber}
            onFinish={onFinish_registration}
            form={registration_form}
          />
        </Panel>
        <Panel header="SCREENING" key="4">
          <Row style={{ fontWeight: "bold" }}>
            TANF Drug Testing Program Requirements
          </Row>
          <Row>
            <TANFDT felonyAnswer={props.felonyAnswer} onFinish={onFinish_tanfdt} form={tanfdt_form} />
          </Row>

          <Row style={{ fontWeight: "bold" }}>
            TANF ONLY and WORK PROGRAM COOP
          </Row>
          <Row>
            <TANFWPCOOP onFinish={onFinish_tanfwpcoop} form={tanfwpcoop_form} />
          </Row>
          <Row style={{ fontWeight: "bold" }}>CHILD CARE ONLY</Row>
          <Row>
            <CHILDCAREONLY
              onFinish={onFinish_childcareonly}
              form={childcareonly_form}
            />
          </Row>
          <Row style={{ fontWeight: "bold" }}>CC/TANF ONLY</Row>
          <Row>
            <CC_TANF onFinish={onFinish_cctanf} form={cctanf_form} />
          </Row>
        </Panel>
        <Panel header="SYSTEM ENTRY" key="5">
          <Row style={{ marginTop: "10px" }}>
            <ScreenName_VR
              onFinish={onFinish_screennamevr}
              form={screennamevr_form}
            />
          </Row>
          <Row>
            <Col span={4}>Screen Name: </Col>
            <Col style={{ fontWeight: "bold" }}>PROGRAM WITHDRAW</Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <ScreenName_PW
              onFinish={onFinish_screennamepw}
              form={screennamepw_form}
            />
          </Row>
        </Panel>
        <Panel header="CASE SUMMARY" key="6">
          <Row style={{ marginTop: "10px" }}>
            <CS_AllPrograms
              onFinish={onFinish_csallprograms}
              form={csallprograms_form}
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <CS_AuthRep onFinish={onFinish_csauthrep} form={csauthrep_form} />
          </Row>
        </Panel>
        <Panel header="CUSTOMER INFORMATION (Local Tab) Non- Financial" key="7">
          <Row style={{ marginTop: "10px" }}>
            <CS_SummaryScreen
              onFinish={onFinish_cssummaryscreen}
              form={cssummaryscreen_form}
            />
          </Row>
          <Row>
            <Col span={4}>Screen Name: </Col>
            <Col style={{ fontWeight: "bold" }}>
              REPORTING PAGE (Reviews/IR ONLY)
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <ReportingPage
              onFinish={onFinish_reportingpage}
              form={reportingpage_form}
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <INDEMOSCREEN
              onFinish={onFinish_indemoscreen}
              form={indemoscreen_form}
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <CITIDENTITYLIST
              onFinish={onFinish_citidentitylist}
              form={citidentitylist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <HOUSEHOLDSTATUSLIST
              onFinish={onFinish_householdstatuslist}
              form={householdstatuslist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <RELATIONSHIPLIST
              onFinish={onFinish_relationshiplist}
              form={relationshiplist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <PREGNANCYLIST
              onFinish={onFinish_pregnancylist}
              form={pregnancylist_form}
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <OTHERPROGRAMLIST
              onFinish={onFinish_otherprogramlist}
              form={otherprogramlist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <NONCOMPLIANCELIST
              onFinish={onFinish_noncompliancelist}
              form={noncompliancelist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <TIMELIMITSUMMARY
              onFinish={onFinish_timelimitsummary}
              form={timelimitsummary_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <PURCHASEPREPARELIST
              onFinish={onFinish_purchasepreparelist}
              form={purchasepreparelist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <SCHOOLATTLIST
              onFinish={onFinish_schoolattlist}
              form={schoolattlist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <LIVINGARRANGEMENT
              onFinish={onFinish_livingarrangement}
              form={livingarrangement_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <NONCUSTPARENTLIST
              onFinish={onFinish_noncustparentlist}
              form={noncustparentlist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <MEDCONDLIST
              onFinish={onFinish_medcondlist}
              form={medcondlist_form}
            />
          </Row>
        </Panel>
        <Panel header="CUSTOMER INFORMATION LOCAL TAB - FINANCIAL" key="8">
          <Row style={{ marginTop: "10px" }}>
            <EMPLISTINCLIST
              onFinish={onFinish_emplistinclist}
              form={emplistinclist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <RESOURCELIST
              onFinish={onFinish_resourcelist}
              form={resourcelist_form}
            />
          </Row>
        </Panel>
        <Panel header="SHELTER" key="9">
          <Row style={{ marginTop: "10px" }}>
            <EXPENSELIST
              onFinish={onFinish_expenselist}
              form={expenselist_form}
            />
          </Row>
        </Panel>
        <Panel header="DEPENDENT CARE/ CSS" key="10">
          <Row>
            <Col span={4}>Screen Name: </Col>
            <Col style={{ fontWeight: "bold" }}>EXPENSE LIST (FA, TANF)</Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <DCEXPENSELIST
              onFinish={onFinish_dcexpenselist}
              form={dcexpenselist_form}
            />
          </Row>
        </Panel>
        <Panel header="MEDICAL" key="11">
          <Row>
            <Col span={4}>Screen Name: </Col>
            <Col style={{ fontWeight: "bold" }}>EXPENSE LIST (FA, TANF)</Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <MDEXPENSELIST
              onFinish={onFinish_mdexpenselist}
              form={mdexpenselist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <MDCAREEXPENSELIST
              onFinish={onFinish_mdcareexpenselist}
              form={mdcareexpenselist_form}
            />
          </Row>
        </Panel>
        <Panel header="CUSTOMER INFORMATION – CHILD CARE" key="12">
          <Row style={{ marginTop: "10px" }}>
            <CHILDCARENEEDLIST
              onFinish={onFinish_childcareneedlist}
              form={childcareneedlist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <PCSCHEDULEDLIST
              onFinish={onFinish_pcscheduledlist}
              form={pcscheduledlist_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <FAMPLANCHILDCARE
              onFinish={onFinish_famplanchildcare}
              form={famplanchildcare_form}
            />
          </Row>
          <Row>
            <Col span={4}>Screen Name: </Col>
            <Col style={{ fontWeight: "bold" }}>
              TANF Suspicion Based Drug Testing (Only those who meet
              suspicion-based indicators)
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <TANFSUSPENSIONBASED
              onFinish={onFinish_tanfsuspensionbased}
              form={tanfsuspensionbased_form}
            />
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <DVSASS onFinish={onFinish_dvsass} form={dvsass_form} />
          </Row>
        </Panel>
        <Panel header="INFORMING CLIENTS" key="13">
          <Row>
            <Col span={4}>Screen Name: </Col>
            <Col style={{ fontWeight: "bold" }}>EBT</Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <ICEBT onFinish={onFinish_icebt} form={icebt_form} />
          </Row>
          <Row>
            <Col span={4}>Screen Name: </Col>
            <Col style={{ fontWeight: "bold" }}>EXPEDITED STATUS</Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <EXPEDITEDSTATUS
              onFinish={onFinish_expeditedstatus}
              form={expeditedstatus_form}
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={4}>Screen Name: </Col>
            <Col style={{ fontWeight: "bold" }}>
              MANDATORY ABAWD / E&T / WORK REGISTRANT SCRIPTS
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <ICMAWRS onFinish={onFinish_icmawrs} form={icmawrs_form} />
          </Row>
          <Row style={{ marginTop: "10px", fontWeight: "bold" }}>
            VERIFICATIONS LIST
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <VERIFICATIONSLIST
              onFinish={onFinish_verificationslist}
              form={verificationslist_form}
            />
          </Row>
          <Row style={{ marginTop: "10px", fontWeight: "bold" }}>TO DO</Row>
          <Row style={{ marginTop: "10px" }}>
            <TODO onFinish={onFinish_todo} form={todo_form} />
          </Row>
          <Row style={{ marginTop: "10px", fontWeight: "bold" }}>RUN EDBC</Row>
          <Row style={{ marginTop: "10px" }}>
            <RUNEDBC onFinish={onFinish_runedbc} form={runedbc_form} />
          </Row>
        </Panel>
        <Panel header="NARRATIVE" key="14">
          <Row style={{ marginTop: "10px" }}>
            <NARRATIVE agentName={props.agentName} onFinish={onFinish_narrative} form={narrative_form} />
          </Row>
        </Panel>
      </Collapse>
      <Row style={{ marginTop: 15 }}>
        <Col span={4}>
          <label>Form Status:</label>
        </Col>
        <Col span={12}>
          <Radio.Group
            // defaultValue="COMPLETED"
            // buttonStyle="solid"
            style={{ width: "100%" }}
            onChange={radioChange}
            value={completed}
          >
            <Radio value="COMPLETED">COMPLETED</Radio>
            <Radio value="INCOMPLETE">FOR UPDATE</Radio>
          </Radio.Group>
        </Col>
      </Row>
      {assessmentForm ? (
        <Alert
          message="Please complete the form/s."
          banner
          closable
          showIcon
          type="error"
          style={{ marginBottom: "15px" }}
        />
      ) : (
        ""
      )}
      {completed !== "" ? (
        <Row gutter={2} style={{ margin: 15 }}>
          <Col span={12}>
            <Popconfirm
              title="Click Yes to submit."
              description="Are you sure the details provided are final?"
              onConfirm={submit_forms}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => {
                  datasource = [];
                  answer_call_form
                    .validateFields()
                    .then((e) => {
                      console.log("[answer_call_form][VALIDATION]", e);
                      datasource.push({ "TANF/CC/FOOD ASSISTANCE PROGRAM": e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[answer_call_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[answer_call_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "TANF/CC/FOOD ASSISTANCE PROGRAM": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  registration_form
                    .validateFields()
                    .then((e) => {
                      console.log("[registration_form1][VALIDATION]", e);
                      datasource.push({ REGISTRATION: e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[registration_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[registration_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          REGISTRATION: errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  right_resp_form
                    .validateFields()
                    .then((e) => {
                      console.log("[right_resp_form][ERROR-VALIDATION]", e);

                      datasource.push({ "RIGHTS AND RESPONSIBILITIES": e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[right_resp_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[right_resp_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "RIGHTS AND RESPONSIBILITIES": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  tanfdt_form
                    .validateFields()
                    .then((e) => {
                      console.log("[tanfdt_form][ERROR-VALIDATION]", e);

                      datasource.push({
                        "TANF Drug Testing Program Requirements": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log("[tanfdt_form][ERROR-VALIDATION]", errors);
                      if (errors.errorFields.length !== 0) {
                        console.log("[tanfdt_form][ERROR-VALIDATION]", errors);
                        datasource.push({
                          "TANF Drug Testing Program Requirements":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  tanfwpcoop_form
                    .validateFields()
                    .then((e) => {
                      console.log("[tanfwpcoop_form][ERROR-VALIDATION]", e);

                      datasource.push({ "TANF ONLY and WORK PROGRAM COOP": e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[tanfwpcoop_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[tanfwpcoop_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "TANF ONLY and WORK PROGRAM COOP": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  childcareonly_form
                    .validateFields()
                    .then((e) => {
                      console.log("[childcareonly_form][ERROR-VALIDATION]", e);

                      datasource.push({ "CHILD CARE ONLY": e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[childcareonly_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[childcareonly_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "CHILD CARE ONLY": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  cctanf_form
                    .validateFields()
                    .then((e) => {
                      console.log("[cctanf_form][ERROR-VALIDATION]", e);
                      datasource.push({ "CC/TANF ONLY": e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log("[cctanf_form][ERROR-VALIDATION]", errors);
                      if (errors.errorFields.length !== 0) {
                        console.log("[cctanf_form][ERROR-VALIDATION]", errors);
                        datasource.push({
                          "CC/TANF ONLY": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  screennamevr_form
                    .validateFields()
                    .then((e) => {
                      console.log("[screennamevr_form][ERROR-VALIDATION]", e);

                      datasource.push({ "VOTER REGISTRATION": e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[screennamevr_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[screennamevr_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "VOTER REGISTRATION": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  screennamepw_form
                    .validateFields()
                    .then((e) => {
                      console.log("[screennamepw_form][ERROR-VALIDATION]", e);
                      datasource.push({ "PROGRAM WITHDRAW": e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[screennamepw_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[screennamepw_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "PROGRAM WITHDRAW": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  csallprograms_form
                    .validateFields()
                    .then((e) => {
                      console.log("[csallprograms_form][ERROR-VALIDATION]", e);
                      datasource.push({ "CASE SUMMARY (All Programs)": e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[csallprograms_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[csallprograms_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "CASE SUMMARY (All Programs)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  csauthrep_form
                    .validateFields()
                    .then((e) => {
                      console.log("[csauthrep_form][ERROR-VALIDATION]", e);
                      datasource.push({ "AUTHORIZED REP": e });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log("[csauthrep_form][ERROR-VALIDATION]", errors);
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[csauthrep_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "AUTHORIZED REP": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  cssummaryscreen_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[cssummaryscreen_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "CONTACT SUMMARY SCREEN (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[cssummaryscreen_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[cssummaryscreen_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "CONTACT SUMMARY SCREEN (All Programs)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  reportingpage_form
                    .validateFields()
                    .then((e) => {
                      console.log("[reportingpage_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "REPORTING PAGE (Reviews/IR ONLY)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[reportingpage_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[reportingpage_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "REPORTING PAGE (Reviews/IR ONLY)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  indemoscreen_form
                    .validateFields()
                    .then((e) => {
                      console.log("[indemoscreen_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "INDIVIDUAL DEMOGRAPHICS SCREEN (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[indemoscreen_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[indemoscreen_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "INDIVIDUAL DEMOGRAPHICS SCREEN (All Programs)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  citidentitylist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[citidentitylist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "CITIZENSHIP/ IDENTITY LIST (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[citidentitylist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[citidentitylist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "CITIZENSHIP/ IDENTITY LIST (All Programs)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  householdstatuslist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[householdstatuslist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "HOUSEHOLD STATUS LIST": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[householdstatuslist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[householdstatuslist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "HOUSEHOLD STATUS LIST": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  relationshiplist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[relationshiplist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "RELATIONSHIP LIST (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[relationshiplist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[relationshiplist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "RELATIONSHIP LIST (All Programs)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  pregnancylist_form
                    .validateFields()
                    .then((e) => {
                      console.log("[pregnancylist_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "PREGNANCY LIST (All Programs)": e,
                      });

                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[pregnancylist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[pregnancylist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "PREGNANCY LIST (All Programs)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  otherprogramlist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[otherprogramlist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "OTHER PROGRAM LIST (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[otherprogramlist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[otherprogramlist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "OTHER PROGRAM LIST (All Programs)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  noncompliancelist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[noncompliancelist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "NON-COMPLIANCE LIST (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[noncompliancelist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[noncompliancelist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "NON-COMPLIANCE LIST (All Programs)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  timelimitsummary_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[timelimitsummary_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "TIME LIMIT SUMMARY (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[timelimitsummary_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[timelimitsummary_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "TIME LIMIT SUMMARY (All Programs)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  purchasepreparelist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[purchasepreparelist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "PURCHASE AND PREPARE LIST (FA)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[purchasepreparelist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[purchasepreparelist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "PURCHASE AND PREPARE LIST (FA)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  schoolattlist_form
                    .validateFields()
                    .then((e) => {
                      console.log("[schoolattlist_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "SCHOOL ATTENDANCE LIST (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[schoolattlist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[schoolattlist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "SCHOOL ATTENDANCE LIST (All Programs)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  livingarrangement_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[livingarrangement_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "LIVING ARRANGEMENTS": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[livingarrangement_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[livingarrangement_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "LIVING ARRANGEMENTS": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  noncustparentlist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[noncustparentlist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "NONCUSTODIAL PARENT LIST (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[noncustparentlist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[noncustparentlist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "NONCUSTODIAL PARENT LIST (All Programs)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  medcondlist_form
                    .validateFields()
                    .then((e) => {
                      console.log("[medcondlist_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "MEDICAL CONDITION LIST (FA, TANF)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[medcondlist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[medcondlist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "MEDICAL CONDITION LIST (FA, TANF)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  emplistinclist_form
                    .validateFields()
                    .then((e) => {
                      console.log("[emplistinclist_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "EMPLOYMENT LIST /INCOME LIST": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[emplistinclist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[emplistinclist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "EMPLOYMENT LIST /INCOME LIST": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  resourcelist_form
                    .validateFields()
                    .then((e) => {
                      console.log("[resourcelist_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "RESOURCE LIST (All Programs)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[resourcelist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[resourcelist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "RESOURCE LIST (All Programs)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  expenselist_form
                    .validateFields()
                    .then((e) => {
                      console.log("[expenselist_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "SHELTER - EXPENSE LIST (FA, TANF)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[expenselist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[expenselist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "SHELTER - EXPENSE LIST (FA, TANF)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  dcexpenselist_form
                    .validateFields()
                    .then((e) => {
                      console.log("[dcexpenselist_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "DEPENDENT CARE/ CSS - EXPENSE LIST (FA, TANF)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[dcexpenselist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[dcexpenselist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "DEPENDENT CARE/ CSS - EXPENSE LIST (FA, TANF)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  mdexpenselist_form
                    .validateFields()
                    .then((e) => {
                      console.log("[mdexpenselist_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "MEDICAL - EXPENSE LIST (FA, TANF)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[mdexpenselist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[mdexpenselist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "MEDICAL - EXPENSE LIST (FA, TANF)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  mdcareexpenselist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[mdcareexpenselist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "MEDICARE EXPENSE LIST (FA Only)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[mdcareexpenselist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[mdcareexpenselist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "MEDICARE EXPENSE LIST (FA Only)": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  childcareneedlist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[childcareneedlist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "CHILD CARE NEED LIST – PARENT AND/ OR CHILD CARE NEED LIST – CHILD (CC Only)":
                          e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[childcareneedlist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[childcareneedlist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "CHILD CARE NEED LIST – PARENT AND/ OR CHILD CARE NEED LIST – CHILD (CC Only)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  pcscheduledlist_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[pcscheduledlist_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "PARENT SCHEDULE LIST AND/ OR CHILD SCHEDULE LIST (CC Only)":
                          e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[pcscheduledlist_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[pcscheduledlist_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "PARENT SCHEDULE LIST AND/ OR CHILD SCHEDULE LIST (CC Only)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  famplanchildcare_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[famplanchildcare_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "FAMILY PLAN – CHILD CARE LIST (CC Only)": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[famplanchildcare_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[famplanchildcare_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "FAMILY PLAN – CHILD CARE LIST (CC Only)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  tanfsuspensionbased_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[tanfsuspensionbased_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "TANF Suspicion Based Drug Testing (Only those who meet suspicion-based indicators)":
                          e,
                      });

                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[tanfsuspensionbased_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[tanfsuspensionbased_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "TANF Suspicion Based Drug Testing (Only those who meet suspicion-based indicators)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  dvsass_form
                    .validateFields()
                    .then((e) => {
                      console.log("[dvsass_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "DOMESTIC VIOLENCE, SEXUAL ASSAULT, OR STALKING SCREENING (REQUIRED)":
                          e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log("[dvsass_form][ERROR-VALIDATION]", errors);
                      if (errors.errorFields.length !== 0) {
                        console.log("[dvsass_form][ERROR-VALIDATION]", errors);
                        datasource.push({
                          "DOMESTIC VIOLENCE, SEXUAL ASSAULT, OR STALKING SCREENING (REQUIRED)":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  icebt_form
                    .validateFields()
                    .then((e) => {
                      console.log("[icebt_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        EBT: e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log("[icebt_form][ERROR-VALIDATION]", errors);
                      if (errors.errorFields.length !== 0) {
                        console.log("[icebt_form][ERROR-VALIDATION]", errors);
                        datasource.push({
                          EBT: errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  expeditedstatus_form
                    .validateFields()
                    .then((e) => {
                      console.log(
                        "[expeditedstatus_form][ERROR-VALIDATION]",
                        e
                      );
                      datasource.push({
                        "EXPEDITED STATUS": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log(
                        "[expeditedstatus_form][ERROR-VALIDATION]",
                        errors
                      );
                      if (errors.errorFields.length !== 0) {
                        console.log(
                          "[expeditedstatus_form][ERROR-VALIDATION]",
                          errors
                        );
                        datasource.push({
                          "EXPEDITED STATUS": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  icmawrs_form
                    .validateFields()
                    .then((e) => {
                      console.log("[icmawrs_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        "MANDATORY ABAWD / E&T / WORK REGISTRANT SCRIPTS": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      console.log("[icmawrs_form][ERROR-VALIDATION]", errors);
                      if (errors.errorFields.length !== 0) {
                        console.log("[icmawrs_form][ERROR-VALIDATION]", errors);
                        datasource.push({
                          "MANDATORY ABAWD / E&T / WORK REGISTRANT SCRIPTS":
                            errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  verificationslist_form
                    .validateFields()
                    .then((e) => {
                      // console.log(
                      //   "[verificationslist_form][ERROR-VALIDATION]",
                      //   e
                      // );
                      datasource.push({
                        "VERIFICATIONS LIST": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      // console.log(
                      //   "[verificationslist_form][ERROR-VALIDATION]",
                      //   errors
                      // );
                      if (errors.errorFields.length !== 0) {
                        // console.log(
                        //   "[verificationslist_form][ERROR-VALIDATION]",
                        //   errors
                        // );
                        datasource.push({
                          "VERIFICATIONS LIST": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  todo_form
                    .validateFields()
                    .then((e) => {
                      // console.log("[todo_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        TODO: e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      // console.log("[todo_form][ERROR-VALIDATION]", errors);
                      if (errors.errorFields.length !== 0) {
                        // console.log("[todo_form][ERROR-VALIDATION]", errors);
                        datasource.push({
                          TODO: errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  runedbc_form
                    .validateFields()
                    .then((e) => {
                      // console.log("[runedbc_form1][ERROR-VALIDATION]", e);
                      datasource.push({
                        "RUN EDBC": e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      // console.log("[runedbc_form][ERROR-VALIDATION]", errors);
                      if (errors.errorFields.length !== 0) {
                        // console.log("[runedbc_form][ERROR-VALIDATION]", errors);
                        datasource.push({
                          "RUN EDBC": errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                  narrative_form
                    .validateFields()
                    .then((e) => {
                      // console.log("[narrative_form][ERROR-VALIDATION]", e);
                      datasource.push({
                        NARRATIVE: e,
                      });
                      setAssessmentForm(false);
                    })
                    .catch((errors) => {
                      // console.log("[narrative_form][ERROR-VALIDATION]", errors);
                      if (errors.errorFields.length !== 0) {
                        // console.log("[narrative_form][ERROR-VALIDATION]", errors);
                        datasource.push({
                          NARRATIVE: errors.values,
                        });
                        setAssessmentForm(true);
                      }
                    });
                }}
              >
                Submit
              </Button>
            </Popconfirm>
          </Col>
          <Col span={12}>
            <Button type="primary" style={{ width: "100%" }}>
              Cancel
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  );
};
export default CustomerForm;
